
const SmallNews = ({ imageSrc, imageAlt, newsHeadline, timestamp }) => {
  const currentTime = new Date().getTime();
  const newsTime = Date.parse(timestamp);
  const isLessThan48Hours = (currentTime - newsTime) < (48 * 60 * 60 * 1000);

  return (
    <a href="/blog" className={`small-news-wrapper`}>
      <div className={`news-breaking-wrapper ${isLessThan48Hours ? 'active' : ''}`}>
      New!
      </div>
      <div className="small-news-image-wrapper">
        <div className="image-offset">
          <img src={imageSrc} alt={imageAlt} className="small-news-img"></img>
        </div>
      </div>
      <div className="small-news-content">
        <h4 className="small-news-header">{newsHeadline}</h4>
      </div>
    </a>
  );
};

export default SmallNews;

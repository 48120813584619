import React from "react";
import { Link } from "react-router-dom";
import Crazytoggle from "./crazy-toggle";

const Items = [
  { text: "Home", link: "/home" },
  { text: "Portfolio", link: "/portfolio" },
  { text: "Blog", link: "/Blog" },
  { text: "About Me", link: "/aboutme" },
];

function Header({ currentPath }) {
  return (
    <header className="header-nav">
      <div className="xxs-col centered">
        <div>Nis Website WIP</div>
        <div className="s-row flex-1 justify-center">
          {Items.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              className={`header-nav-btn centered ${
                currentPath === item.link ? "active" : ""
              }`}
            >
              {item.text}
            </Link>
          ))}
        </div>
      </div>
      <div className="change-toggle">
        <Crazytoggle />
      </div>
    </header>
  );
}

export default Header;

import React from "react";
import Header from "../components/header";
import UserTitle from "../components/h2-title";

function AboutMe() {
  const currentPath = window.location.pathname;
  return (
    <>
      <Header currentPath={currentPath} />
      <div className="main-content">
      <UserTitle title={"About Me"} />
        <div className="s-col centered">
          <p>
            I am a full stack web developer with a background in education. I
            have a passion for learning and problem solving. I am a graduate of
            the University of Utah's Full Stack Web Development Bootcamp. I am
            proficient in HTML, CSS, JavaScript, Node.js, React.js, MySQL,
            MongoDB, and more. I am a hard worker and a quick learner. I am
            looking for a position where I can continue to learn and grow as a
            developer.
          </p>
          <p>
            I am a Utah native. I have a Bachelor's degree in English from the
            University of Utah. I have worked as a teacher and a tutor. I have
            experience working with students of all ages. I am a hard worker and
            a quick learner. I am looking for a position where I can continue to
            learn and grow as a developer.
          </p>
          
        </div>
      </div>
    </>
  );
}

export default AboutMe;

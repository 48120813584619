import React from 'react';
import Header from '../components/header';
import SmallNewsFrontpage from '../components/news-article-frontpage';
import ClassicCarousel from '../components/classic-carousel';
import UserTitle from '../components/h2-title';
import newsArticles from '../data/newsHeadlines.json';

function Portfolio() {
  const currentPath = window.location.pathname;

  // Sort newsArticles by timestamp in descending order
  const sortedNewsArticles = newsArticles.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  return (
    <>
      <Header currentPath={currentPath} />
      <div className='main-content'>
      <UserTitle title={"Blog"} />
        <div className='xl-col'>
          <div className='news-grid'>
            {sortedNewsArticles.map((article, index) => (
              <SmallNewsFrontpage
                key={index}
                imageSrc={article.imageSrc}
                imageAlt={article.imageAlt}
                newsHeadline={article.newsHeadline}
                timestamp={article.timestamp}
              />
            ))}
          </div>

          <ClassicCarousel></ClassicCarousel>
        </div>
      </div>
    </>
  );
}

export default Portfolio;



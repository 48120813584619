import React from "react";
import Header from "../components/header";
import UserTitle from "../components/h2-title";
import Footer from "../components/footer";
import { IonIcon } from "@ionic/react";
import {
  logoCss3,
  logoSass,
  logoHtml5,
  logoNodejs,
  logoReact,
  logoJavascript,
  logoFirebase,
} from "ionicons/icons";
import nisHomeImage from '../images/jpg/nis-home.jpg';


function Home() {
  const currentPath = window.location.pathname;
  const items = [
    {
      imageUrl: "https://pbs.twimg.com/media/GBz-lXdXMAAp6z4?format=jpg",
      title: "Show Reel",
    },
    {
      imageUrl:
        "https://esports.gg/_next/image/?url=https%3A%2F%2Fadmin.esports.gg%2Fwp-content%2Fuploads%2F2024%2F01%2FHLTV-Awards-2023-968x544.jpg&w=1080&q=75",
      title: "Show Reel",
    },
    {
      imageUrl:
        "https://cdn.vox-cdn.com/thumbor/TzZ5B-HcUcncxZvVNTgqNkH4-Zk=/0x0:3011x1447/1570x883/filters:focal(1123x329:1603x809):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/66954486/VALORANT_Jett_Red_crop.0.jpg",
      title: "Show Reel",
    },
    {
      imageUrl: "https://pbs.twimg.com/media/GBz-lXdXMAAp6z4?format=jpg",
      title: "Show Reel",
    },
    // Add more items here
  ];

  return (
    <>
      <Header currentPath={currentPath} />
      <div className="main-content">
        <UserTitle title={"Home"} />
        <div className="about-me-home-wrapper xs-col">
          <div className="xl-row centered flex-wrap">
            <div className="about-me-home-image-wrapper">
              <img
                src={nisHomeImage}
                alt="Nis"
                className="about-me-home-image"
              />
            </div>
            <div className="about-me-home-text">
              Hi, I'm Nis! I'm a passionate software developer with experience
              in web development. I love creating innovative and user-friendly
              applications using the latest technologies.
            </div>
          </div>
          <TechStack />
        </div>
        <div className="home-show-reel-wrapper l-row">
          {items.map((item, index) => (
            <a href="nisrepenning.web.app" className="home-show-reel" key={index}>
              <div className="home-show-reel-image">
                <img src={item.imageUrl} alt={item.title} />
              </div>
              <div className="home-show-reel-text">
                <h3>{item.title}</h3>
              </div>
            </a>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;

function TechStack() {
  return (
    <div className="tech-stack text-center font">
      <h4>Tech Stack</h4>
      <div className="xs-row justify-center">
        <IonIcon className="techStackIcons" icon={logoCss3}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoSass}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoHtml5}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoNodejs}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoReact}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoJavascript}></IonIcon>
        <IonIcon className="techStackIcons" icon={logoFirebase}></IonIcon>
      </div>
    </div>
  );
}

import './styling/App.scss';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Home from './pages/Home';
import Blog from './pages/Blog';
import AboutMe from './pages/AboutMe';
import Portfolio from './pages/Portfolio';
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-QfUBzMlqPr1o86vZNyAhHeyJR83Ke6E",
  authDomain: "nisportfolio.firebaseapp.com",
  projectId: "nisportfolio",
  storageBucket: "nisportfolio.appspot.com",
  messagingSenderId: "413990066788",
  appId: "1:413990066788:web:2689e6725f48bb40065d71",
  measurementId: "G-XNP8YHWS98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  return (
    <>
      <Router>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/AboutMe" element={<AboutMe />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes> 
      </Router>
    </>
  );
}


export default App;





import React, { useRef, useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { logoLinkedin, logoTwitter, logoYoutube } from "ionicons/icons";

const Footer = () => {

    const footerRef = useRef(null);
        const [paddingBottom, setPaddingBottom] = useState(0);

        useEffect(() => {
            const updatePadding = () => {
                const footerHeight = footerRef.current.offsetHeight;
                setPaddingBottom(footerHeight + 140);
            };

            updatePadding();

            window.addEventListener("resize", updatePadding);

            return () => {
                window.removeEventListener("resize", updatePadding);
            };
        }, []);

        useEffect(() => {
            document.body.style.paddingBottom = `${paddingBottom}px`;
        }, [paddingBottom]);
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <footer className="footer" ref={footerRef}>
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>
            <ul className="social-icon">
                <li className="social-icon-item">
                    <a className="social-icon-link" href="https://www.youtube.com/channel/UCih3auywGexNAli588T35eQ">
                        <IonIcon icon={logoYoutube}></IonIcon>
                    </a>
                </li>
                <li className="social-icon-item">
                    <a className="social-icon-link" href="https://twitter.com/NiZeFreaK">
                        <IonIcon icon={logoTwitter}></IonIcon>
                    </a>
                </li>
                <li className="social-icon-item">
                    <a className="social-icon-link" href="https://www.linkedin.com/in/nis-repenning-971b73150/">
                        <IonIcon icon={logoLinkedin}></IonIcon>
                    </a>
                </li>
            </ul>
            <ul className="menu">
                <li className="menu-item">
                    <a className="menu-link" href="home">
                        Home
                    </a>
                </li>
                <li className="menu-item">
                    <a className="menu-link" href="/portfolio">
                        Portfolio
                    </a>
                </li>
                <li className="menu-item">
                    <a className="menu-link" href="/Blog">
                        Blog
                    </a>
                </li>
                <li className="menu-item">
                    <a className="menu-link" href="/aboutme">
                        About Me
                    </a>
                </li>
            </ul>
            <p>&copy;{getCurrentYear()} Nis Repenning | All Rights Reserved</p>
        </footer>
    );
};

export default Footer;

import React from "react";
import Header from "../components/header";
import UserTitle from "../components/h2-title"

const Portfolio = () => {
  const currentPath = window.location.pathname;
  return (
    <>
      <Header currentPath={currentPath} />
      <div>
        <div className="main-content">
        <UserTitle title={"Portfolio"} />
          <div className="portfolio-segment segment-1">
            <h2>Brokkr</h2>
            {/* Add your content for segment 1 here */}
          </div>
          <div className="portfolio-segment segment-2">
            <h2>GearFreak.com</h2>
            {/* Add your content for segment 2 here */}
          </div>
          <div className="portfolio-segment segment-3">
            <h2>HLTV Awards Page</h2>
            {/* Add your content for segment 3 here */}
          </div>
          <div className="portfolio-segment segment-4">
            <h2>Futbin.com</h2>
            {/* Add your content for segment 3 here */}
          </div>
          <div className="portfolio-segment segment-5">
            <h2>This page</h2>
            {/* Add your content for segment 3 here */}
          </div>
          {/* Create more segments here if needed */}
        </div>
      </div>
    </>
  );
};

export default Portfolio;

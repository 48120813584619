import React from "react";

function ClassicCarousel() {
    const getImageSrc = () => {
        return "https://via.placeholder.com/150";
    };

    const getImageAlt = () => {
        return "placeholder";
    };

    const getCaptionContent = (index) => {
        return `Caption ${index}`;
    };

    return (
        <div className="carousel-wrapper s-row centered">
            <div className="carousel">
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(1)}</div>
                </div>
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(2)}</div>
                </div>
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(3)}</div>
                </div>
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(4)}</div>
                </div>
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(5)}</div>
                </div>
                <div className="carousel-item">
                    <img
                        className="carousel-image"
                        src={getImageSrc()}
                        alt={getImageAlt()}
                    />
                    <div className="carousel-caption">{getCaptionContent(6)}</div>
                </div>
            </div>
        </div>
    );
}

export default ClassicCarousel;

import React, { useState } from "react";

const Crazytoggle = () => {
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
        const BUTTON = document.querySelector("button");
        const SYNC = document.querySelector("#sync");
        const IS_PRESSED = BUTTON.matches("[aria-pressed=true]");
        if (SYNC.checked)
            document.body.setAttribute("data-dark-mode", IS_PRESSED ? false : true);
        BUTTON.setAttribute("aria-pressed", IS_PRESSED ? false : true);
    };

    return (
        <>
            <div class="controls">
                <label for="sync"></label>
                <input id="sync" type="checkbox" />
            </div>
            <button class="toggle" aria-pressed="false" title="Toggle Dark Mode" onClick={handleToggle}>
                <span class="toggle__indicator-wrapper">
                    <span class="toggle__indicator">
                        <span class="toggle__star">
                            <span class="sun">
                                <span class="moon">
                                    <span class="moon__crater"></span>
                                    <span class="moon__crater"></span>
                                    <span class="moon__crater"></span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </button>
        </>
    );
};



export default Crazytoggle;
